import Link from 'next/link';
import { useTranslation } from 'next-i18next';

export default function HomeCTA() {
  const { t } = useTranslation('pages/home');

  return (
    <Link href='trial' className='flex items-center justify-center transition-opacity hover:opacity-80'>
      <div className='mx-auto w-full max-w-[1400px] p-5 lg:pb-40'>
        <div className="overflow-hidden rounded-lg bg-[url('/assets/images/home-cta/image-2.webp')] bg-cover bg-center">
          <div className='relative overflow-hidden rounded-lg p-4 mobile-l:px-8 mobile-l:py-6 desktop:px-[100px] desktop:py-[66px]'>
            <div className='relative z-[1] flex items-center justify-between'>
              <div className='flex flex-col items-start gap-3'>
                <h2 className='max-w-[754px] text-8-5xl font-bold leading-[110%] text-white'>{t('cta')}</h2>
                <div className='max-w-[578px]'>
                  <p className='font-medium leading-[140%] text-white'>{t('cta_description')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}
